import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import Clinician from "./Clinician/Clinician";
import { getAllClinicians } from "../../../services/operations/organizationAPI";

function OrganizationDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const { organization } = useSelector((state) => state.clinicianOrganization);
  const { data } = location.state || {};
  const currentOrg = organization.find((org) => org.ORGID === data?.ORGID);
  const [selectedTab, setSelectedTab] = useState(0);
  const [clinicianData, setClinicianData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllClinicians(token, data?.ORGID);
        setClinicianData(response || []);
      } catch (error) {
        console.log("Could not get position.", error);
      }
    })();
  }, []);

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div className="grid grid-cols-5 gap-6">
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Name
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                {data?.Clinic}
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Legal Name
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                {data?.LegalName}
              </p>
            </div>
            <div className="col-span-3">
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Company Address
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                {data?.HouseNo +
                  ", " +
                  data?.Street +
                  ", " +
                  data?.City +
                  ", " +
                  data?.State +
                  ", " +
                  data?.PinCode +
                  ", " +
                  data?.Country}
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Email
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                {data?.Email}
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Phone No.
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                +{data?.ClinicianData?.Phone}
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                VAT no.
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                CHE-123.456.789 MWST
              </p>
            </div>
            <div className="col-span-2">
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                UID No.
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                CHE-202.462.111
              </p>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="grid grid-cols-5 gap-6">
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Bank Name
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                State Bank of India
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                Bank Account no.
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                0116 2385 2957
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                IBAN no.
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                CH93 0076 2011 6238 5295 7
              </p>
            </div>
            <div>
              <p className="text-zinc-950 text-sm font-medium font-poppins">
                IFSC Code
              </p>
              <p className="text-neutral-600 text-sm font-normal font-poppins">
                RAIFCH22XXX
              </p>
            </div>
          </div>
        );
      case 2:
        if (!currentOrg?.subscription) {
          return (
            <div className="flex flex-col items-center space-y-4">
              <div className="text-neutral-600 text-base font-medium font-poppins">
                No subscription data available.
              </div>
              <button
                onClick={() =>
                  navigate("/subscription-management/subscription-plans", {
                    state: { data: currentOrg, mode: "assign" },
                  })
                }
                className="px-10 py-3.5 bg-white rounded-3xl shadow border border-amber-500 flex items-center space-x-3 hover:scale-105 hover:shadow-lg hover:shadow-amber-200 transition-transform duration-200 active:scale-95"
              >
                <div className="text-black text-sm font-semibold font-poppins">
                  Assign Subscription Plan
                </div>
                <ArrowLongRightIcon className="h-8 text-black text-sm font-semibold" />
              </button>
            </div>
          );
        }
        return (
          <div className="flex flex-row space-x-10">
            <div className="flex-1 space-y-10">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Subscription Plan Name:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {currentOrg?.subscription?.clinicianSubscription?.PlanName}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Included Features:
                </div>
                <div className="flex flex-wrap gap-2 w-1/2">
                  {currentOrg?.subscription?.clinicianSubscription?.Perks.map(
                    (perk) => (
                      <div
                        key={perk.PerkID}
                        className="px-4 py-2 bg-sky-100 rounded-3xl"
                      >
                        <div className="text-sky-700 text-xs font-medium font-poppins">
                          {perk.Perks}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Start Date:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {moment(
                    currentOrg?.subscription?.SubscriptionStartDate
                  ).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Billing Cycle:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {currentOrg?.subscription?.clinicianSubscription?.PlanCycle}
                </div>
              </div>
            </div>

            <div className="flex-1 space-y-10">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Subscription Plan ID:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {currentOrg?.subscription?.SubscriptionID}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Plan Status:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {currentOrg?.subscription?.clinicianSubscription?.Status}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  End Date:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {moment(currentOrg?.subscription?.SubscriptionEndDate).format(
                    "DD/MM/YYYY"
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                  Plan Price:
                </div>
                <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                  {parseInt(
                    currentOrg?.subscription?.clinicianSubscription?.PlanPrice
                  )}{" "}
                  CHF
                </div>
              </div>

              <button
                onClick={() =>
                  navigate("/subscription-management/subscription-plans", {
                    state: { data: currentOrg, mode: "change" },
                  })
                }
                className="px-10 py-3.5 bg-white rounded-3xl shadow border border-amber-500 flex items-center space-x-3 hover:scale-105 hover:shadow-lg hover:shadow-amber-200 transition-transform duration-200 active:scale-95"
              >
                <div className="text-black text-sm font-semibold font-poppins">
                  Change Subscription Plan
                </div>
                <ArrowLongRightIcon className="h-8 text-black text-sm font-semibold" />
              </button>
            </div>
          </div>
        );
      case 3:
        return <Clinician data={clinicianData} org={data} />;

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Organization Details
      </div>

      {/* Organization Card */}
      <div className="max-w-xs bg-white rounded-xl shadow mt-4 p-3">
        <div className="flex row items-center space-x-4">
          <img
            src={data?.Image}
            alt="PatientPic"
            className="w-24 h-24 shadow rounded-full object-cover"
          />
          <div>
            <div className="text-black text-base font-semibold font-poppins">
              {data?.Clinic}
            </div>
            <div className="text-zinc-500 text-base font-medium font-poppins">
              ORG ID: {data?.ORGID}
            </div>
          </div>
        </div>
      </div>

      <div className=" flex flex-row items-center space-x-1 my-4">
        <button
          onClick={() => setSelectedTab(0)}
          className={`w-48 ${
            selectedTab === 0
              ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
              : "text-neutral-600 font-normal"
          }  flex flex-col justify-center items-center`}
        >
          <div className={`text-sm font-poppins leading-tight m-3`}>
            Basic Information
          </div>
          <div
            className={`w-full h-0.5 ${
              selectedTab === 0 ? "bg-sky-400" : "bg-gray-200"
            } rounded-xl`}
          />
        </button>

        <button
          onClick={() => setSelectedTab(1)}
          className={`w-48 ${
            selectedTab === 1
              ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
              : "text-neutral-600 font-normal"
          }  flex flex-col justify-center items-center`}
        >
          <div className={`text-sm font-poppins leading-tight m-3`}>
            Account Details
          </div>
          <div
            className={`w-full h-0.5 ${
              selectedTab === 1 ? "bg-sky-400" : "bg-gray-200"
            } rounded-xl`}
          />
        </button>
        <button
          onClick={() => setSelectedTab(2)}
          className={`w-48 ${
            selectedTab === 2
              ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
              : "text-neutral-600 font-normal"
          }  flex flex-col justify-center items-center`}
        >
          <div className={`text-sm font-poppins leading-tight m-3`}>
            Subscription Details
          </div>
          <div
            className={`w-full h-0.5 ${
              selectedTab === 2 ? "bg-sky-400" : "bg-gray-200"
            } rounded-xl`}
          />
        </button>
        <button
          onClick={() => setSelectedTab(3)}
          className={`w-48 ${
            selectedTab === 3
              ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
              : "text-neutral-600 font-normal"
          }  flex flex-col justify-center items-center`}
        >
          <div className={`text-sm font-poppins leading-tight m-3`}>
            Clinician Details
          </div>
          <div
            className={`w-full h-0.5 ${
              selectedTab === 3 ? "bg-sky-400" : "bg-gray-200"
            } rounded-xl`}
          />
        </button>
      </div>

      <div className="bg-white rounded-xl shadow p-4">
        {renderTabContent()}
        {/* <div className="text-zinc-900 text-lg font-semibold font-poppins leading-loose">
          Company Details
        </div> */}
      </div>
    </div>
  );
}

export default OrganizationDetails;
