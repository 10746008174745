import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalComponent from "../../common/Modal";
import Dropdown from "../../common/Dropdown";
import add_plan_background from "../../../assets/images/OrganizationManagement/add_plan_background.png";
import {
  addSubscriptionPlan,
  updateSubscriptionPlan,
} from "../../../services/operations/clinicianOrganizationAPI";

const PlanForm = ({
  formData,
  setFormData,
  errors,
  setErrors,
  features,
  setFeatures,
  plan,
}) => {
  const { token } = useSelector((state) => state.auth);
  const { organization, perks } = useSelector(
    (state) => state.clinicianOrganization
  );
  const [newFeature, setNewFeature] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [service, setService] = useState(perks);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (plan?.CPSID) {
  //     const filtered = perks.filter((feature) =>
  //       features.includes(feature.PerkID)
  //     );
  //     const result = [...new Set([...perks.slice(0, 3), ...filtered])];
  //     setService(result);
  //     return;
  //   }
  //   const defaultPerks = perks.slice(0, 3);
  //   setService(defaultPerks);
  // }, [plan?.CPSID]);

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "Price":
        maxLength = 6;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handleSuggestions = (input) => {
    const filtered = perks
      .slice(3, perks.length)
      .map((feature) => feature)
      .filter((feature) =>
        feature.Perks.toLowerCase().includes(input.toLowerCase())
      );

    setSuggestions(filtered);
  };

  const submitHandler = async (event) => {
    // event.preventDefault();
    const mandatoryFields = [
      "PlanName",
      "PlanType",
      "Price",
      "Cycle",
      "Status",
    ];

    if (formData.PlanType === "CUSTOM") {
      mandatoryFields.push("ORGID");
    }
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    if (features.length === 0) {
      toast.error("Add at least 1 feature");
      setErrors((prev) => ({
        ...prev,
        Features: "Add at least 1 feature",
      }));
      return;
    }

    const body = {
      PlanName: formData.PlanName,
      PlanAccess: formData.PlanType,
      PlanPrice: formData.Price,
      PerksID: features,
      PlanCycle: formData.Cycle,
      Currency: "CHF",
      Status: formData.Status,
    };

    if (formData.PlanType === "CUSTOM") {
      body.ORGID = [formData.ORGID];
    }

    if (plan?.CPSID) {
      dispatch(updateSubscriptionPlan(token, plan?.CPSID, body, navigate));
    } else {
      dispatch(addSubscriptionPlan(token, body, navigate));
    }
  };

  return (
    <div className="w-1/2">
      <div className="">
        {/* Plan Name */}
        <div className="mb-6">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Define Plan Name
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.PlanName.length}/25
              </p>
            </div>
            <input
              type="text"
              name="PlanName"
              value={formData.PlanName}
              onChange={handleOnChange}
              maxLength={25}
              placeholder="Ex: Yearly subscription individual"
              className={`w-full ${
                errors.PlanName ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Plan Type */}
        <div className="mb-6">
          <label>
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Select a Plan Type
              <sup className="text-red-600">*</sup>
            </p>
            <Dropdown
              error={errors.PlanType}
              name="PlanType"
              options={PLANTYPE}
              onSelect={handleSelect}
              value={formData.PlanType}
              labelKey={"label"}
              valueKey={"value"}
            />
          </label>
        </div>
        {/* Select Organization */}
        {formData.PlanType === "CUSTOM" && (
          <div className="mb-6">
            <label>
              <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                Select Organization
                <sup className="text-red-600">*</sup>
              </p>
              <Dropdown
                error={errors.ORGID}
                placeholder={"Select Organization"}
                name="ORGID"
                options={organization}
                onSelect={handleSelect}
                value={formData.ORGID}
                labelKey={"Clinic"}
                valueKey={"ORGID"}
              />
            </label>
          </div>
        )}
        {/* Price */}
        <div className="mb-6">
          <label>
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Add Plan Price
              <sup className="text-red-600">*</sup>
            </p>
            <input
              type="number"
              name="Price"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => {
                e.preventDefault();
                e.target.blur();
              }}
              value={formData.Price}
              onChange={handleOnChange}
              placeholder="Ex:  CHF 1500"
              className={`w-full ${
                errors.Price ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Plan Cycle, Plan Status */}
        <div className="mb-6 flex space-x-4">
          {/* Plan Cycle */}
          <label className="flex-1">
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Plan Cycle
              <sup className="text-red-600">*</sup>
            </p>
            <Dropdown
              error={errors.Cycle}
              name="Cycle"
              options={PLANCYCLE}
              onSelect={handleSelect}
              value={formData.Cycle}
              labelKey={"label"}
              valueKey={"value"}
            />
          </label>
          {/* Plan Status */}
          <label className="flex-1">
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Plan Status
              <sup className="text-red-600">*</sup>
            </p>
            <Dropdown
              error={errors.Status}
              name="Status"
              options={PLANSTATUS}
              onSelect={handleSelect}
              value={formData.Status}
              labelKey={"label"}
              valueKey={"value"}
            />
          </label>
        </div>
        <div className="mb-6">
          <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
            Features Included
            <sup className="text-red-600">*</sup>
          </p>
          <div className="flex flex-row items-center flex-wrap gap-2">
            {service?.map((feature) => (
              <button
                key={feature?.PerkID}
                onClick={() => {
                  if (features.includes(feature?.PerkID)) {
                    setFeatures(
                      features.filter((id) => id !== feature?.PerkID)
                    );
                  } else {
                    setFeatures([...features, feature?.PerkID]);
                  }
                  setErrors((prev) => ({
                    ...prev,
                    Features: "",
                  }));
                }}
                className={`px-4 py-2.5 ${
                  features.includes(feature?.PerkID)
                    ? "bg-sky-100 border border-sky-400 "
                    : "bg-gray-100"
                }  rounded-3xl flex items-center justify-center space-x-2.5`}
              >
                <div
                  className={`text-sm font-poppins leading-none ${
                    features.includes(feature?.PerkID)
                      ? "text-sky-400 font-semibold"
                      : "text-zinc-900 font-normal"
                  }`}
                >
                  {feature?.Perks}
                </div>
                {features.includes(feature?.PerkID) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8327 4.1665L4.16602 15.8332M4.16602 4.1665L15.8327 15.8332"
                      stroke="#3CA5DC"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3.3335V16.6668"
                      stroke="#1C1C1C"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33398 10H16.6673"
                      stroke="#1C1C1C"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
            ))}
          </div>
          {/* Add new button / input field */}
          {/* {showInput ? (
            <div className="relative mt-5">
              <input
                type="text"
                value={newFeature}
                autoFocus
                onChange={(e) => {
                  setNewFeature(e.target.value);
                  handleSuggestions(e.target.value);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setNewFeature("");
                    setSuggestions([]);
                    setShowInput(false);
                  }, 100); // Delay to allow onClick to be processed
                }}
                className="text-black text-xs font-normal font-poppins bg-gray-100 rounded-full p-4 border caret-sky-400 focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent"
                placeholder="Add new feature"
              />
              {suggestions.length > 0 && (
                <ul className="absolute left-0 mt-1 w-1/2 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[17vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  {suggestions.map((suggestion, index) => {
                    return (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onMouseDown={(e) => e.preventDefault()} // Prevent blur event
                        onClick={() => {
                          setService([...service, suggestion]);
                          setFeatures([...features, suggestion?.PerkID]);
                          setNewFeature("");
                          setSuggestions([]);
                          setShowInput(false);
                        }}
                      >
                        {suggestion?.Perks}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          ) : (
            <button
              onClick={() => setShowInput(true)}
              className="px-4 py-2.5 bg-gray-100 rounded-3xl border border-neutral-600 flex justify-center items-center gap-2.5 mt-5 active:scale-95"
            >
              <div className="text-center text-zinc-900 text-sm font-normal font-poppins leading-none">
                Add new
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3.3335V16.6668"
                  stroke="#1C1C1C"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.33398 10H16.6673"
                  stroke="#1C1C1C"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )} */}

          {errors.Features && (
            <p className="text-rose-500 text-xs font-semibold font-poppins leading-none mt-4">
              Add at least 1 feature
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        <button
          onClick={() => navigate(-1)}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
            Cancel
          </div>
        </button>
        <button
          onClick={submitHandler}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            {plan?.CPSID ? "Save Changes" : "Add Plan"}
          </div>
        </button>
      </div>
    </div>
  );
};

function AddSubscriptionPlan() {
  const location = useLocation();
  const plan = location.state || {};
  const [showBackView, setShowBackView] = useState(false);
  const [interactions, setInteractions] = useState("Limited");
  const [features, setFeatures] = useState([]);

  const [formData, setFormData] = useState({
    PlanName: "",
    PlanType: "FOR ALL",
    Price: "",
    Cycle: "Monthly",
    Status: "ACTIVE",
    Features: [],
    ORGID: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (plan?.CPSID) {
      setFormData({
        PlanName: plan?.PlanName,
        PlanType: plan?.PlanAccess,
        Price: parseFloat(plan?.PlanPrice),
        Cycle: plan?.PlanCycle,
        Status: plan?.Status,
        ORGID:
          plan?.PlanAccess === "CUSTOM" ? plan?.Organizations[0]?.ORGID : "",
        Features: plan?.Perks?.map((feature) => feature?.PerkID),
      });
      setFeatures(plan?.Perks?.map((feature) => feature?.PerkID));
    }
  }, [plan?.CPSID]);

  return (
    <div>
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {plan?.CPSID ? "Edit Subscription Plan" : "Add New Subscription Plan"}
      </div>
      <div className="flex gap-8 mt-4">
        <PlanForm
          showBackView={showBackView}
          setShowBackView={setShowBackView}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          interactions={interactions}
          setInteractions={setInteractions}
          features={features}
          setFeatures={setFeatures}
          plan={plan}
        />
        <div className="flex flex-1 justify-end items-end">
          <img
            src={add_plan_background}
            alt=""
            className="w-80 h-80 object-contain"
          />
        </div>
      </div>
    </div>
  );
}

export default AddSubscriptionPlan;

const PLANTYPE = [
  {
    label: "For All",
    value: "FOR ALL",
  },
  {
    label: "Custom",
    value: "CUSTOM",
  },
];

const PLANSTATUS = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "On Hold",
    value: "ON HOLD",
  },
];

const PLANCYCLE = [
  {
    label: "Yearly Billed Plan",
    value: "Yearly",
  },
  {
    label: "Monthly Billed Plan",
    value: "Monthly",
  },
];
