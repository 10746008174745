// const BASE_URL = "https://localhost:4000/api/v1";
// const BASE_URL = "https://swissapi.alleshealth.com/v1/admin";
const BASE_URL = "https://devapireyou.alleshealth.com/v1/admin";

// AUTH ENDPOINTS
export const endpoints = {
  LOGIN_API: BASE_URL + "/adminLogin",
};

// PATIENTS ENDPOINTS
export const patientEndpoints = {
  GET_ALL_PATIENTS_API: BASE_URL + "/userDetails",
};

// CLINIC ENDPOINTS
export const clinicEndpoints = {
  GET_ALL_REFEERED_CLINIC_API: BASE_URL + "/referredClinicsDetails",
};

// SUBSCRIPTION ENDPOINTS
export const subscriptionEndpoints = {
  GET_ALL_ORGANIZATION_SUBSCRIPTIONS_API:
    BASE_URL + "/getAllOrganizationSubscriptionInfo",
  GET_ALL_SUBSCRIPTION_PLANS_API:
    BASE_URL + "/getClinicianSubscriptionPlansForAdmin",
  GET_ALL_PERKS_API: BASE_URL + "/getAllClinicianPerksForAdminPortal",
  ADD_SUBSCRIPTION_PLAN_API: BASE_URL + "/createClinicianSubscriptionPlan",
  UPDATE_SUBSCRIPTION_PLAN_API: BASE_URL + "/updateClinicianSubscriptionPlan",
  DELETE_SUBSCRIPTION_PLAN_API: BASE_URL + "/deleteClinicianSubscriptionPlan",
  ASSIGN_SUBSCRIPTION_PLAN_API: BASE_URL + "/assignPlanToOrganization",
};

// ORGANIZATION ENDPOINTS
export const organizationEndpoints = {
  GET_ALL_ORGANIZATIONS_API: BASE_URL + "/getAllOrganizationDetails",
  ADD_ORGANIZATION_API: BASE_URL + "/registerOrganization",
  UPDATE_ORGANIZATION_API: BASE_URL + "/updateOrganization",
  DELETE_ORGANIZATION_API: BASE_URL + "/deleteOrganization",
  UPLOAD_ORGANIZATION_LOGO_API: BASE_URL + "/uploadOrganizationLogo",
  GET_ORGANIZATION_CLINICIANS_API: BASE_URL + "/getClinicianDetails",
  ADD_ORGANIZATION_CLINICIAN_API: BASE_URL + "/registerClinician",
  UPDATE_ORGANIZATION_CLINICIAN_API: BASE_URL + "/updateClinicianDetails",
  DELETE_ORGANIZATION_CLINICIAN_API: BASE_URL + "/deleteClinician",
};
