import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Pagination from "../../common/Pagination";
import { useSelector } from "react-redux";
import { GiSettingsKnobs } from "react-icons/gi";
import FilterDropdown from "../../common/FilterDropDown";
import moment from "moment";

function SubscriptionManagement() {
  const navigate = useNavigate();
  const { organization, loading } = useSelector(
    (state) => state.clinicianOrganization
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOrganization = organization.filter((org) => {
    return org?.Clinic?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrganizations = filteredOrganization.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  if (loading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="">
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Manage Subscription Plans for Organizations
        </div>
      </div>

      <div className="flex flex-row items-center justify-between mt-6">
        <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
          <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
          <input
            type="text"
            placeholder="Search organization here"
            value={searchTerm}
            onChange={handleSearchChange}
            className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
          />
        </label>

        <div className="flex flex-row items-center space-x-5">
          {/* <FilterDropdown /> */}

          <button
            onClick={() => {
              navigate("/subscription-management/subscription-plans");
            }}
            className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
          >
            <GiSettingsKnobs className="text-white text-md font-bold" />
            <div className="text-white text-sm font-normal font-poppins">
              Manage All Plans
            </div>
          </button>
        </div>
      </div>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-sky-50 border-b border-sky-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Organization's Details
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Subscription Plan
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Next Billing Date
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentOrganizations.map((org) => (
              <tr
                key={org?.ORGID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <div className="flex items-center">
                    <img
                      src={org?.Image}
                      alt="PatientPic"
                      className="w-10 h-10 rounded-full object-contain mr-3"
                    />
                    <div>
                      <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                        {org?.Clinic}
                      </p>
                      <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                        ORG: {org?.ORGID}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {org?.subscription?.clinicianSubscription?.PlanName || "N/A"}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {org?.subscription?.SubscriptionEndDate
                    ? moment(org.subscription.SubscriptionEndDate).format(
                        "DD/MM/YYYY"
                      )
                    : "N/A"}
                </td>
                <td className="p-4">
                  {org.subscription ? (
                    <button
                      onClick={() =>
                        navigate("manage-subscription", {
                          state: { data: org },
                        })
                      }
                      className="py-1 px-4 bg-white rounded-3xl border border-sky-400 hover:bg-sky-100"
                    >
                      <div className=" text-sky-400 text-xs font-medium font-poppins">
                        See Details
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        navigate(
                          "/subscription-management/subscription-plans",
                          {
                            state: { data: org, mode: "assign" },
                          }
                        )
                      }
                      className="py-1 px-4 bg-white rounded-3xl border border-lime-700 hover:bg-emerald-50"
                    >
                      <div className=" text-lime-700 text-xs font-medium font-poppins">
                        Add a Plan
                      </div>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        totalItems={organization.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default SubscriptionManagement;
