import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  organization: [],
  plans: [],
  perks: [],
};

const organizationSlice = createSlice({
  name: "organization",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setOrganization(state, value) {
      state.organization = value.payload;
    },
  },
});

export const { setLoading, setOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
