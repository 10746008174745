import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../common/Pagination";
import FilterDropdown from "../../common/FilterDropDown";
import { FaPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../common/Modal";
import {
  assignSubscriptionPlan,
  deleteSubscriptionPlan,
} from "../../../services/operations/clinicianOrganizationAPI";
import deleteRedIcon from "../../../assets/images/deleteRedIcon.svg";
import Tooltip from "../../common/Tooltip";

const MODES = {
  DEFAULT: "default",
  ASSIGN: "assign",
  CHANGE: "change",
};

function ManageSubscriptionPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const { plans } = useSelector((state) => state.clinicianOrganization);
  const { data, mode } = location.state || {};
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const itemsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredPlans = plans.filter((plan) => {
    const matchesSearchTerm = plan.PlanName.toLowerCase().includes(
      searchTerm.toLowerCase()
    );
    const matchesFilter = selectedFilter
      ? plan.PlanCycle.toLowerCase() === selectedFilter.toLowerCase() ||
        plan.PlanAccess.toLowerCase() === selectedFilter.toLowerCase()
      : true;
    return matchesSearchTerm && matchesFilter;
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPlans = filteredPlans.slice(indexOfFirstItem, indexOfLastItem);

  const closeDeleteModal = () => {
    setSelectedPlan(null);
    setShowDeleteModal(false);
  };

  const handleDeleteConciergePlan = () => {
    dispatch(deleteSubscriptionPlan(token, selectedPlan?.CPSID));
    setSelectedPlan(null);
    closeDeleteModal();
  };

  const assignPlan = (plan) => {
    const body = {
      CPSID: plan?.CPSID,
      ORGID: data?.ORGID,
      CustomerEmail: data?.Email,
    };
    dispatch(assignSubscriptionPlan(token, body, navigate));
  };

  console.log("====================================");
  console.log("plans", plans);
  console.log("====================================");

  return (
    <div className="">
      <ModalComponent
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteRedIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Delete Subscription Plan '{selectedPlan?.PlanName}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Are you sure you want to delete this subscription plan? This action
            cannot be undone.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={closeDeleteModal}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleDeleteConciergePlan}
              className="w-48 h-11 flex items-center justify-center bg-rose-400 rounded-lg active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Delete
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          {mode === MODES.ASSIGN
            ? "Assign Subscription Plan"
            : mode === MODES.CHANGE
            ? "Change Subscription Plan"
            : "Manage Subscription Plans"}
        </div>
      </div>

      <div className="flex flex-row items-center justify-between mt-6">
        <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
          <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
          <input
            type="text"
            placeholder="Search plan here"
            value={searchTerm}
            onChange={handleSearchChange}
            className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
          />
        </label>

        <div className="flex flex-row items-center space-x-5">
          <FilterDropdown onFilterChange={handleFilterChange} />

          <button
            onClick={() => {
              navigate(
                "/subscription-management/subscription-plans/add-subscription-plans"
              );
            }}
            className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
          >
            <FaPlus className="text-white text-md" />
            <div className="text-white text-sm font-normal font-poppins">
              Add New Plan
            </div>
          </button>
        </div>
      </div>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-sky-50 border-b border-sky-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Plan Name
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Plan Overview Status
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPlans?.map((plan) => (
              <tr
                key={plan?.CPSID}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className="p-4">
                  <div className="flex items-center">
                    <div
                      className={`w-8 h-8 flex items-center justify-center rounded-full border mr-3 text-black ${
                        plan?.PlanCycle === "Yearly"
                          ? "border-yellow-400"
                          : "border-red-400 "
                      }`}
                    >
                      {plan?.PlanName.slice(0, 1)}
                    </div>
                    <div>
                      <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                        {plan?.PlanName}
                      </p>
                      <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                        {plan?.PlanCycle}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="p-4 flex space-x-4">
                  <div className="px-4 py-2.5 bg-sky-100 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-neutral-700 text-xs font-semibold font-poppins leading-none">
                      CHF {parseFloat(plan.PlanPrice).toFixed(0)}
                    </div>
                  </div>
                  <div className="px-4 py-2.5 bg-sky-100 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-neutral-700 text-xs font-semibold font-poppins leading-none">
                      {plan?.Status === "ACTIVE" ? "Active Now" : "On Hold"}
                    </div>
                  </div>
                </td>
                {mode === MODES.ASSIGN || mode === MODES.CHANGE ? (
                  <td className="p-4">
                    <button
                      onClick={() => assignPlan(plan)}
                      className="py-1 px-4 bg-white rounded-3xl border border-sky-400 hover:bg-sky-100"
                    >
                      <div className=" text-sky-400 text-xs font-medium font-poppins">
                        {mode === MODES.CHANGE ? "Change Plan" : "Assign Plan"}
                      </div>
                    </button>
                  </td>
                ) : (
                  <td className="p-4">
                    <div className="flex flex-row items-center space-x-2">
                      <Tooltip message="Edit Plan">
                        <button
                          onClick={() =>
                            navigate("add-subscription-plans", { state: plan })
                          }
                          className=""
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                              stroke="#545454"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                              stroke="#545454"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                      <Tooltip message="Delete Plan">
                        <button
                          onClick={() => {
                            setSelectedPlan(plan);
                            setShowDeleteModal(true);
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5"
                              stroke="#DA5E60"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                            <path
                              d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5"
                              stroke="#DA5E60"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.5 16.5V10.5"
                              stroke="#DA5E60"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                            <path
                              d="M14.5 16.5V10.5"
                              stroke="#DA5E60"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        totalItems={plans.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default ManageSubscriptionPlan;
