import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
import { FaList } from "react-icons/fa6";
import Pagination from "../../../common/Pagination";
import ClinicianTable from "./ClinicianTable";

function Clinician({ data, org }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const filteredClinician = data?.filter((p) => {
    const dob = new Date(p.DOB);
    const month = dob.toLocaleString("default", { month: "long" });
    return `${p.Name} ${p.DOB} ${month}`
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentClinician = filteredClinician?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div>
      {/* Header */}
      <div className="flex items-center justify-between">
        <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100 rounded-2xl py-2 px-4 gap-2">
          <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
          <input
            type="text"
            placeholder="Search for Clinician"
            value={searchInput}
            onChange={handleSearchChange}
            className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
          />
        </label>
        <button
          onClick={() => {
            navigate(
              "/organization-management/organization-details/manage-clinician",
              {
                state: { org: org },
              }
            );
          }}
          className="px-4 py-2 bg-sky-400 rounded-lg shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
        >
          <FaPlus className="text-white text-sm" />
          <div className="text-white text-sm font-normal font-poppins">
            Add New Clinician
          </div>
        </button>
      </div>

      <div className="flex flex-row items-center justify-between mt-5"></div>
      <ClinicianTable clinician={currentClinician} org={org} />
      <Pagination
        totalItems={data?.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default Clinician;
