import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalComponent from "../../../common/Modal";
import Dropdown from "../../../common/Dropdown";
import add_plan_background from "../../../../assets/images/OrganizationManagement/add_plan_background.png";
import {
  addSubscriptionPlan,
  updateSubscriptionPlan,
} from "../../../../services/operations/clinicianOrganizationAPI";
import { Steps, Switch } from "antd";
import MultiSelectDropdown from "../../../common/MultiSelectDropdown";
import { OrganizationTypes } from "../../../../data/organization-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countries } from "../../../../data/country";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import {
  registerClinician,
  registerOrganization,
  updateClinician,
  updateOrganization,
  uploadOrganizationLogo,
} from "../../../../services/operations/organizationAPI";
import moment from "moment-timezone";
import imageCompression from "browser-image-compression";
import upload_icon from "../../../../assets/images/upload_icon.svg";
import { languageList } from "../../../../data/language-list";
import { doctorSpeciality } from "../../../../data/doctor-speciality";
import { nurseSpeciality } from "../../../../data/nurse-speciality";
import { healthManagerSpeciality } from "../../../../data/healthManager-speciality";

const PlanForm = ({
  formData,
  setFormData,
  errors,
  setErrors,
  current,
  setCurrent,
  image,
  setImage,
  rolesConfig,
  roles,
  setRoles,
  org,
  user,
}) => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateStepFields = () => {
    let isValid = true;
    const newErrors = {};

    // Define required fields for each step
    const requiredFields = {
      0: [
        "Name",
        "Gender",
        "Email",
        "PhoneNumber",
        "JoiningDate",
        "Experience",
      ],
      1: ["Speciality", "Languages"],
      2: ["StreetName", "HouseNumber", "City", "State", "PinCode", "Country"],
    };

    if (requiredFields[current]) {
      requiredFields[current].forEach((field) => {
        // Ensure formData[field] is a string before calling trim()
        if (
          !formData[field] ||
          (typeof formData[field] === "string" && !formData[field].trim())
        ) {
          newErrors[field] = "This field is required";
          isValid = false;
        } else if (
          typeof formData[field] !== "string" &&
          !String(formData[field]).trim()
        ) {
          newErrors[field] = "This field is required";
          isValid = false;
        }
      });
    }

    // Update the errors state
    setErrors(newErrors);
    // Show error toast only if there is an error
    if (!isValid) {
      toast.error("Please fill all the required fields");
    }
    return isValid;
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "Experience":
        maxLength = 2;
        break;
      case "StartTime":
        maxLength = 2;
        break;
      case "EndTime":
        maxLength = 2;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // Remove numeric characters if the field is EducationDegree
    let newValue = value;
    if (name === "EducationDegree") {
      newValue = value.replace(/[0-9]/g, "");
    }

    // For other types, handle validation without removing non-numeric characters
    if (newValue.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  const next = () => {
    if (current < steps.length - 1 && validateStepFields()) {
      setCurrent(current + 1);
    } else if (current === steps.length - 1 && validateStepFields()) {
      submitHandler();
    }
  };

  const prev = () => {
    if (current === 0) {
      navigate(-1);
    } else {
      setCurrent(current - 1);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        ImageLocation: res?.OrganizationLogoURL,
      }));
      setErrors((prev) => ({
        ...prev,
        ImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const handleRoleToggle = (roleKey) => {
    setRoles((prev) => {
      const newRoles = { ...prev };
      newRoles[roleKey] = !prev[roleKey];

      // Handle role combinations
      if (newRoles[roleKey]) {
        switch (roleKey) {
          case "doctor":
          case "telehealthDoctor":
            newRoles.nurse = false;
            newRoles.healthManager = false;
            break;
          case "nurse":
            newRoles.doctor = false;
            newRoles.telehealthDoctor = false;
            newRoles.healthManager = false;
            break;
          case "healthManager":
            newRoles.doctor = false;
            newRoles.telehealthDoctor = false;
            newRoles.nurse = false;
            break;
          default:
            break;
        }
      }

      // Clear Speciality for specific roles
      if (["doctor", "nurse", "healthManager"].includes(roleKey)) {
        setFormData((prev) => ({
          ...prev,
          Speciality: [],
        }));
      }

      return newRoles;
    });
  };

  const submitHandler = async () => {
    const userTypes = Object.keys(roles)
      .filter((role) => roles[role])
      .map((role) => {
        switch (role) {
          case "admin":
            return "Admin";
          case "doctor":
            return "Doctor";
          case "telehealthDoctor":
            return "TeleHealth Doctor";
          case "nurse":
            return "Nurse";
          case "healthManager":
            return "Health Manager";
          default:
            return "";
        }
      });

    const data = {
      Name: formData?.Name,
      Email: formData?.Email,
      PhoneNumber: formData?.PhoneNumber,
      CountryCode: `+${formData?.PhoneNumber?.slice(0, 2)}`,
      ORGID: org?.ORGID,
      Gender: formData?.Gender,
      Experience: parseInt(formData.Experience),
      UserType: userTypes,
      PreferredLanguage: formData.Languages.map((item) => item),
      JoiningDate: formData.JoiningDate,
      TimeZone: moment.tz.guess(),
      HouseNo: formData.HouseNumber,
      Street: formData.StreetName,
      PinCode: formData.PinCode,
      City: formData.City,
      State: formData.State,
      Country: formData.Country,
    };

    if (roles.doctor) {
      data.Speciality = formData.Speciality.map((item) => item);
    } else {
      data.Speciality = [formData.Speciality];
    }

    if (formData?.ImageLocation) {
      data.ProfileImageUrl = formData?.ImageLocation;
    }

    if (user?.DID) {
      dispatch(updateClinician(token, user?.DID, data, navigate));
    } else {
      dispatch(registerClinician(token, data, navigate));
    }
  };

  const renderInputFields = () => {
    switch (current) {
      case 0:
        return (
          <>
            {/* Profile Picture */}
            <div className="mb-6">
              <label
                className={` border-dashed ${
                  errors.ImageLocation ? "border-red-500" : "border-zinc-500"
                }  border-2 rounded-md p-4 flex flex-col items-center justify-center cursor-pointer`}
              >
                {formData?.ImageLocation ? (
                  <>
                    <div className="w-20 h-20 shadow-lg rounded-full shadow-neutral-400">
                      <img
                        src={image}
                        alt="upload icon"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>

                    <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex cursor-pointer mt-3">
                      <p className="text-sky-400 text-xs font-semibold font-open">
                        Change Logo
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-14 h-14 bg-slate-100 rounded-full flex justify-center items-center">
                      <img
                        src={upload_icon}
                        alt=""
                        className="w-6 h-9 object-contain cursor-pointer"
                      />
                    </div>
                    <div className="text-center text-stone-950 text-sm font-medium font-poppins leading-none mt-2">
                      Profile Picture
                    </div>
                    <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-3 mt-1">
                      (Click to Add)
                    </div>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="Image"
                  value={undefined}
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            {/* Name */}
            <div className="mb-6">
              <label>
                <div className="flex items-center justify-between">
                  <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                    Clinician Name
                    <sup className="text-red-600">*</sup>
                  </p>
                  <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                    {formData?.Name.length}/50
                  </p>
                </div>
                <input
                  type="text"
                  name="Name"
                  value={formData.Name}
                  onChange={handleOnChange}
                  maxLength={50}
                  placeholder="Ex: Sundeep Gaba"
                  className={`w-full ${
                    errors.Name ? "border-red-500" : ""
                  }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Gender */}
            <div className="mb-6">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Gender
                <sup className="text-red-600">*</sup>
              </p>
              <div className="flex space-x-2">
                <button
                  className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Male"
                      ? "border-sky-100 text-white bg-sky-400"
                      : "border-sky-500 text-sky-400  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Male" })}
                >
                  <IoMaleSharp />
                  <div className="text-sm font-medium font-poppins">Male</div>
                </button>
                <button
                  className={`w-full  flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Female"
                      ? "border-sky-100 text-white bg-rose-500"
                      : "border-rose-500 text-rose-500  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Female" })}
                >
                  <IoFemaleSharp />
                  <div className="text-sm font-semibold font-poppins">
                    Female
                  </div>
                </button>
                <button
                  className={`w-full flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-4 border ${
                    formData.Gender === "Diverse"
                      ? "border-sky-100 text-white bg-lime-500"
                      : "border-lime-500  text-lime-500  bg-white"
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, Gender: "Diverse" })
                  }
                >
                  <LiaTransgenderSolid />
                  <div className="text-sm font-semibold font-poppins">
                    Diverse
                  </div>
                </button>
              </div>
            </div>
            {/* Email & Phone */}
            <div className="mb-6 flex space-x-4">
              {/* Email */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="email"
                  name="Email"
                  value={formData.Email}
                  onChange={handleOnChange}
                  placeholder="Input email here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>
            {/* Date of Joining & Experience */}
            <div className="mb-6 flex space-x-4">
              {/*  Date of Joining */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Date of Joining
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="date"
                  name="JoiningDate"
                  value={moment(formData.JoiningDate).format("YYYY-MM-DD")}
                  placeholder="Select Date of Birth"
                  onChange={handleOnChange}
                  // max={moment().format("YYYY-MM-DD")}
                  className={`w-full accent-sky-500 text-sky-400 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.JoiningDate ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              {/* Years of Experience */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Enter yrs of experience ( in yrs)
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  name="Experience"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.Experience}
                  onChange={handleOnChange}
                  placeholder="ex: 3"
                  className={`w-full ${
                    errors.Experience ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
          </>
        );
      case 1:
        return (
          <>
            {/* Clinician Type */}
            <div className="mb-6">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-4">
                Assign Roles
                <sup className="text-red-600">*</sup>
              </p>
              <div className="space-y-4">
                {rolesConfig.map((role) => (
                  <div key={role.key} className="flex items-center space-x-4">
                    <Switch
                      checked={roles[role.key]}
                      onChange={() => handleRoleToggle(role.key)}
                    />
                    <span className="text-gray-700 font-medium">
                      {role.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {/* Speciality */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Speciality
                  <sup className="text-red-600">*</sup>
                </p>
                {roles.doctor ? (
                  <MultiSelectDropdown
                    placeholder={"Select"}
                    error={errors.Speciality}
                    name="Speciality"
                    options={doctorSpeciality}
                    onSelect={handleSelect}
                    value={formData.Speciality}
                    labelKey={"label"}
                    valueKey={"value"}
                  />
                ) : roles.nurse ? (
                  <Dropdown
                    placeholder={"Select"}
                    error={errors.Speciality}
                    name="Speciality"
                    options={nurseSpeciality}
                    onSelect={handleSelect}
                    value={formData.Speciality}
                    labelKey={"label"}
                    valueKey={"value"}
                  />
                ) : roles.healthManager ? (
                  <Dropdown
                    placeholder={"Select"}
                    error={errors.Speciality}
                    name="Speciality"
                    options={healthManagerSpeciality}
                    onSelect={handleSelect}
                    value={formData.Speciality}
                    labelKey={"Speciality"}
                    valueKey={"SID"}
                  />
                ) : (
                  <MultiSelectDropdown
                    placeholder={"Select"}
                    error={errors.Speciality}
                    name="Speciality"
                    options={doctorSpeciality}
                    onSelect={handleSelect}
                    value={formData.Speciality}
                    labelKey={"label"}
                    valueKey={"value"}
                  />
                )}
              </label>
            </div>
            {/* Languages */}
            <div className="mb-6">
              <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                Languages Known
                <sup className="text-red-600">*</sup>
              </p>
              <MultiSelectDropdown
                placeholder="Select Languages"
                error={errors.Languages}
                name="Languages"
                options={languageList}
                onSelect={handleSelect}
                value={formData.Languages}
                labelKey="name"
                valueKey="id"
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            {/* Street Name */}
            <div className="mb-6 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Street Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="StreetName"
                  value={formData.StreetName}
                  onChange={handleOnChange}
                  placeholder="Enter Street Name"
                  className={`w-full ${
                    errors.StreetName ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  House Number
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="HouseNumber"
                  value={formData.HouseNumber}
                  onChange={handleOnChange}
                  placeholder="Enter House Number"
                  className={`w-full ${
                    errors.HouseNumber ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* City, State */}
            <div className="mb-6 flex space-x-4">
              {/* City */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  City
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="City"
                  value={formData.City}
                  onChange={handleOnChange}
                  maxLength={25}
                  placeholder="Enter City"
                  className={`w-full ${
                    errors.City ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>

              {/* State */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  State
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="State"
                  value={formData.State}
                  onChange={handleOnChange}
                  maxLength={25}
                  placeholder="Enter State"
                  className={`w-full ${
                    errors.State ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* PinCode, Country */}
            <div className="mb-6 flex space-x-4">
              {/* PinCode */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Pincode
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="PinCode"
                  value={formData.PinCode}
                  onChange={handleOnChange}
                  maxLength={25}
                  placeholder="Enter Pincode"
                  className={`w-full ${
                    errors.PinCode ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>

              {/* Country */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Country
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  placeholder={"Select Country"}
                  error={errors.Country}
                  name="Country"
                  options={countries}
                  onSelect={handleSelect}
                  value={formData.Country}
                  labelKey="label"
                  valueKey="value"
                  enableSearch={true}
                />
              </label>
            </div>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <div className="w-1/2">
      <div className="bg-white shadow-md rounded-md p-4">
        {renderInputFields()}
      </div>
      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        <button
          onClick={prev}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
            {current === 0 ? "Cancel" : "Back"}
          </div>
        </button>
        <button
          onClick={next}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            {current === steps.length - 1 ? "Submit" : "Next"}
          </div>
        </button>
      </div>
    </div>
  );
};

function AddClinician() {
  const location = useLocation();
  const { org, user } = location.state || {};
  const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    ImageLocation: "",
    Name: "",
    Gender: "Male",
    Email: "",
    PhoneNumber: "",
    JoiningDate: "",
    Experience: "",
    StreetName: "",
    HouseNumber: "",
    State: "",
    City: "",
    PinCode: "",
    Country: "",
    Role: ["Doctor"],
    Speciality: [],
    Languages: [],
  });
  const [errors, setErrors] = useState({});

  const rolesConfig = [
    { key: "admin", label: "Admin" },
    { key: "doctor", label: "Doctor" },
    { key: "telehealthDoctor", label: "Telehealth Doctor" },
    { key: "nurse", label: "Nurse" },
    { key: "healthManager", label: "Health Manager" },
  ];

  const [roles, setRoles] = useState({
    admin: false,
    doctor: true,
    telehealthDoctor: false,
    nurse: false,
    healthManager: false,
  });

  useEffect(() => {
    if (user?.DID) {
      setFormData({
        ImageLocation: user.ProfileImageUrl || "",
        Name: user.Name || "",
        Gender: user.Gender || "Male",
        Email: user.Email || "",
        PhoneNumber: user.Phone || "",
        JoiningDate: user.JoiningDate || "",
        Experience: user.Experience?.toString() || "",
        StreetName: user.Street || "",
        HouseNumber: user.HouseNo || "",
        State: user.State || "",
        City: user.City || "",
        PinCode: user.PinCode || "",
        Country: user.Country || "",
        Speciality: user.RelatedSpecialities?.map((item) => item.SID) || [],
        Languages: user.RelatedLanguages?.map((item) => item.LanguageID) || [],
      });
      setImage(user.Image || "");
      setRoles({
        admin: user.UserType.includes("Admin") || false,
        doctor: user.UserType.includes("Doctor") || false,
        telehealthDoctor: user.UserType.includes("TeleHealth Doctor") || false,
        nurse: user.UserType.includes("Nurse") || false,
        healthManager: user.UserType.includes("Health Manager") || false,
      });
    }
  }, [user?.DID]);

  const onChange = (value) => {
    setCurrent(value);
  };

  console.log("====================================");
  console.log(user);
  console.log("====================================");

  return (
    <div className="bg-white min-h-full scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {user?.DID ? "Edit Clinician" : "Add New Clinician"}
      </div>
      <div className="w-1/2 my-5">
        <Steps
          size="small"
          labelPlacement="vertical"
          current={current}
          items={items}
          className="custom-steps"
          onChange={user?.DID ? onChange : undefined}
        />
      </div>
      <div className="flex gap-8 mt-4">
        <PlanForm
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          current={current}
          setCurrent={setCurrent}
          image={image}
          setImage={setImage}
          rolesConfig={rolesConfig}
          roles={roles}
          setRoles={setRoles}
          org={org}
          user={user}
        />
        <div className="flex flex-1 justify-end items-end">
          <img
            src={add_plan_background}
            alt=""
            className="w-80 h-80 object-contain"
          />
        </div>
      </div>
    </div>
  );
}

export default AddClinician;

const steps = [
  {
    title: "Basic Details",
  },
  {
    title: "Speciality",
  },
  {
    title: "Address Info",
  },
];
