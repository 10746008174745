import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { organizationEndpoints } from "../apis";
import { setLoading, setOrganization } from "../../slices/organizationSlice";
import { getBucketURL } from "../../utils/s3BucketUrl";

const {
  GET_ALL_ORGANIZATIONS_API,
  ADD_ORGANIZATION_API,
  UPDATE_ORGANIZATION_API,
  UPLOAD_ORGANIZATION_LOGO_API,
  GET_ORGANIZATION_CLINICIANS_API,
  ADD_ORGANIZATION_CLINICIAN_API,
  UPDATE_ORGANIZATION_CLINICIAN_API,
} = organizationEndpoints;

// GET ALL ORGANIZATION
export function getAllOrganization(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_ORGANIZATIONS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      const organization = response?.data?.OrganizationDetails?.map(
        async (org) => {
          if (org?.Profile_Image) {
            const Image = await getBucketURL(org?.Profile_Image);
            org.Image = Image;
          }
          return org;
        }
      );

      const updatedOrganization = await Promise.all(organization);
      dispatch(setOrganization(updatedOrganization));
    } catch (error) {
      console.log("GET_ALL_ORGANIZATIONS_API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// REGISTER ORGANIZATION
export function registerOrganization(token, body, navigate) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector("POST", ADD_ORGANIZATION_API, body, {
        Authorization: `Bearer ${token}`,
      });

      if (response?.status !== 201) {
        throw new Error(response?.data?.message);
      }

      toast.success("Organization Added Successfully");
      dispatch(getAllOrganization(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD_ORGANIZATION_API ERROR............", error);
      toast.error(error?.message);
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// EDIT ORGANZIATION
export function updateOrganization(token, ID, body, navigate) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_ORGANIZATION_API + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Organization Updated Successfully");
      dispatch(getAllOrganization(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE_ORGANIZATION_API ERROR............", error);
      toast.error(error?.message);
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPLOAD ORGANIZATION LOGO
export async function uploadOrganizationLogo(token, formData) {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      UPLOAD_ORGANIZATION_LOGO_API,
      formData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD IMAGE API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
}

export const getAllClinicians = async (token, ID) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      GET_ORGANIZATION_CLINICIANS_API + "/" + ID,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    }

    const updatedClinicians = await Promise.all(
      response?.data?.Clinicians?.map(async (clinician) => {
        if (clinician?.ProfileImageUrl) {
          const Image = clinician.ProfileImageUrl ? await getBucketURL(clinician?.ProfileImageUrl) : "";
          clinician.Image = Image;
        }
        return clinician;
      })
    );

    result = updatedClinicians;
  } catch (error) {
    console.log("GET_ORGANIZATION_CLINICIANS_API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

// REGISTER CLINICIAN
export function registerClinician(token, body, navigate) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "POST",
        ADD_ORGANIZATION_CLINICIAN_API,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 201) {
        throw new Error(response?.data?.message);
      }

      toast.success("Clinician Added Successfully");
      navigate(-1);
    } catch (error) {
      console.log("ADD_ORGANIZATION_CLINICIAN_API ERROR............", error);
      toast.error(error?.message);
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// EDIT ORGANZIATION
export function updateClinician(token, ID, body, navigate) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_ORGANIZATION_CLINICIAN_API + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Clinician Updated Successfully");
      navigate(-1);
    } catch (error) {
      console.log("UPDATE_ORGANIZATION_CLINICIAN_API ERROR............", error);
      toast.error(error?.message);
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
