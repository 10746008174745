export const OrganizationTypes = [
  {
    CTID: 1,
    ClinicType: "General Practitioner (GP)",
  },
  {
    CTID: 2,
    ClinicType: "Eye Specialist (Ophthalmology)",
  },
  {
    CTID: 3,
    ClinicType: "Dentist",
  },
  {
    CTID: 4,
    ClinicType: "Aesthetic Clinic",
  },
  {
    CTID: 5,
    ClinicType: "Gynecology Clinic",
  },
  {
    CTID: 6,
    ClinicType: "Pediatrics Clinic",
  },
  {
    CTID: 7,
    ClinicType: "Orthopedic Clinic",
  },
  {
    CTID: 8,
    ClinicType: "Cardiology Clinic",
  },
  {
    CTID: 9,
    ClinicType: "Dermatology Clinic",
  },
  {
    CTID: 10,
    ClinicType: "ENT Clinic (Otolaryngology)",
  },
  {
    CTID: 11,
    ClinicType: "Mental Health Clinic",
  },
  {
    CTID: 12,
    ClinicType: "Physiotherapy Clinic",
  },
  {
    CTID: 13,
    ClinicType: "Oncology Clinic",
  },
  {
    CTID: 14,
    ClinicType: "Endocrinology Clinic",
  },
  {
    CTID: 15,
    ClinicType: "Gastroenterology Clinic",
  },
  {
    CTID: 16,
    ClinicType: "Urology Clinic",
  },
  {
    CTID: 17,
    ClinicType: "Nephrology Clinic",
  },
  {
    CTID: 18,
    ClinicType: "Pulmonology Clinic",
  },
  {
    CTID: 19,
    ClinicType: "Neurology Clinic",
  },
  {
    CTID: 20,
    ClinicType: "Rheumatology Clinic",
  },
  {
    CTID: 21,
    ClinicType: "Sports Medicine Clinic",
  },
  {
    CTID: 22,
    ClinicType: "Sexual Health Clinic",
  },
  {
    CTID: 23,
    ClinicType: "Podiatry Clinic",
  },
  {
    CTID: 24,
    ClinicType: "Allergy & Immunology Clinic",
  },
  {
    CTID: 25,
    ClinicType: "Plastic Surgery Clinic",
  },
  {
    CTID: 26,
    ClinicType: "Pain Management Clinic",
  },
  {
    CTID: 27,
    ClinicType: "Fertility Clinic",
  },
  {
    CTID: 28,
    ClinicType: "Occupational Health Clinic",
  },
  {
    CTID: 29,
    ClinicType: "Radiology Clinic",
  },
  {
    CTID: 30,
    ClinicType: "Hematology Clinic",
  },
  {
    CTID: 31,
    ClinicType: "Geriatrics Clinic",
  },
  {
    CTID: 32,
    ClinicType: "Immunology Clinic",
  },
  {
    CTID: 33,
    ClinicType: "Infectious Disease Clinic",
  },
  {
    CTID: 34,
    ClinicType: "Proctology Clinic",
  },
  {
    CTID: 35,
    ClinicType: "Chiropractic Clinic",
  },
  {
    CTID: 36,
    ClinicType: "Occupational Therapy Clinic",
  },
  {
    CTID: 37,
    ClinicType: "Sleep Medicine Clinic",
  },
  {
    CTID: 38,
    ClinicType: "Bariatric Clinic",
  },
  {
    CTID: 39,
    ClinicType: "Palliative Care Clinic",
  },
  {
    CTID: 40,
    ClinicType: "Speech Therapy Clinic",
  },
  {
    CTID: 41,
    ClinicType: "Wellness Clinic",
  },
  {
    CTID: 42,
    ClinicType: "Diabetes Clinic",
  },
  {
    CTID: 43,
    ClinicType: "Hearing Center (Audiology)",
  },
  {
    CTID: 44,
    ClinicType: "IV Therapy Clinic",
  },
  {
    CTID: 45,
    ClinicType: "Addiction Recovery Clinic",
  },
  {
    CTID: 46,
    ClinicType: "Veterinary Clinic",
  },
  {
    CTID: 47,
    ClinicType: "Alternative Medicine Clinic",
  },
  {
    CTID: 48,
    ClinicType: "Hyperbaric Oxygen Therapy Clinic",
  },
  {
    CTID: 49,
    ClinicType: "Genetics Clinic",
  },
  {
    CTID: 50,
    ClinicType: "Travel Medicine Clinic",
  },
  {
    CTID: 51,
    ClinicType: "Rehabilitation Clinic",
  },
  {
    CTID: 52,
    ClinicType: "Speech & Language Clinic",
  },
  {
    CTID: 53,
    ClinicType: "Plastic & Reconstructive Surgery Clinic",
  },
  {
    CTID: 54,
    ClinicType: "Veterinary Specialty Clinic",
  },
  {
    CTID: 55,
    ClinicType: "Nutrition Clinic",
  },
  {
    CTID: 56,
    ClinicType: "Chronic Pain Clinic",
  },
  {
    CTID: 57,
    ClinicType: "Hair and Scalp Clinic",
  },
  {
    CTID: 58,
    ClinicType: "Vascular Clinic",
  },
  {
    CTID: 59,
    ClinicType: "Orthodontic Clinic",
  },
  {
    CTID: 60,
    ClinicType: "AIDS/HIV Clinic",
  },
  {
    CTID: 61,
    ClinicType: "Emergency Care Clinic",
  },
  {
    CTID: 62,
    ClinicType: "Behavioral Therapy Clinic",
  },
  {
    CTID: 63,
    ClinicType: "Osteopathy Clinic",
  },
  {
    CTID: 64,
    ClinicType: "Family Medicine Clinic",
  },
  {
    CTID: 65,
    ClinicType: "Community Health Clinic",
  },
  {
    CTID: 66,
    ClinicType: "Gastrointestinal Surgery Clinic",
  },
  {
    CTID: 67,
    ClinicType: "Weight Loss Clinic",
  },
  {
    CTID: 68,
    ClinicType: "Wound Care Clinic",
  },
  {
    CTID: 69,
    ClinicType: "Endoscopic Clinic",
  },
  {
    CTID: 70,
    ClinicType: "Veterinary Specialty Referral Clinic",
  },
  {
    CTID: 71,
    ClinicType: "Orthotics and Prosthetics Clinic",
  },
  {
    CTID: 72,
    ClinicType: "Adolescent Medicine Clinic",
  },
  {
    CTID: 73,
    ClinicType: "Sexual Wellness Clinic",
  },
  {
    CTID: 74,
    ClinicType: "Pulmonary Rehabilitation Clinic",
  },
  {
    CTID: 75,
    ClinicType: "Stroke Rehabilitation Clinic",
  },
  {
    CTID: 76,
    ClinicType: "Clinical Genetics Clinic",
  },
  {
    CTID: 77,
    ClinicType: "Perinatal Care Clinic",
  },
  {
    CTID: 78,
    ClinicType: "Adoption and Surrogacy Clinic",
  },
  {
    CTID: 79,
    ClinicType: "Burn Center Clinic",
  },
  {
    CTID: 80,
    ClinicType: "Developmental Pediatrics Clinic",
  },
  {
    CTID: 81,
    ClinicType: "Forensic Medicine Clinic",
  },
  {
    CTID: 82,
    ClinicType: "Occupational Medicine Clinic",
  },
  {
    CTID: 83,
    ClinicType: "Homeopathy Clinic",
  },
  {
    CTID: 84,
    ClinicType: "Chronic Disease Management Clinic",
  },
  {
    CTID: 85,
    ClinicType: "Regenerative Medicine Clinic",
  },
  {
    CTID: 86,
    ClinicType: "Telemedicine Clinic",
  },
  {
    CTID: 87,
    ClinicType: "Asthma and Allergy Clinic",
  },
  {
    CTID: 88,
    ClinicType: "Nuclear Medicine Clinic",
  },
  {
    CTID: 89,
    ClinicType: "Lymphedema Clinic",
  },
  {
    CTID: 90,
    ClinicType: "Sleep Apnea Clinic",
  },
  {
    CTID: 91,
    ClinicType: "Telepsychiatry Clinic",
  },
  {
    CTID: 92,
    ClinicType: "Cancer Survivorship Clinic",
  },
  {
    CTID: 93,
    ClinicType: "Men’s Health Clinic",
  },
  {
    CTID: 94,
    ClinicType: "Veterans’ Health Clinic",
  },
  {
    CTID: 95,
    ClinicType: "Mobile Clinic",
  },
  {
    CTID: 96,
    ClinicType: "Psychosomatic Medicine Clinic",
  },
  {
    CTID: 97,
    ClinicType: "Rare Disease Clinic",
  },
  {
    CTID: 98,
    ClinicType: "Transplant Clinic",
  },
  {
    CTID: 99,
    ClinicType: "Travel Vaccination Clinic",
  },
];
