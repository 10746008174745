export const healthManagerSpeciality = [
  {
    SID: 42,
    Speciality: "Anthropology",
  },
  {
    SID: 43,
    Speciality: "Molecular Biology",
  },
  {
    SID: 44,
    Speciality: "Genetics",
  },
  {
    SID: 45,
    Speciality: "Ecology",
  },
  {
    SID: 46,
    Speciality: "Business",
  },
  {
    SID: 47,
    Speciality: "Chemistry",
  },
  {
    SID: 48,
    Speciality: "Computer Science",
  },
  {
    SID: 49,
    Speciality: "Economics",
  },
  {
    SID: 50,
    Speciality: "Education",
  },
  {
    SID: 51,
    Speciality: "Engineering",
  },
  {
    SID: 52,
    Speciality: "Environmental Science",
  },
  {
    SID: 53,
    Speciality: "History",
  },
  {
    SID: 54,
    Speciality: "Law",
  },
  {
    SID: 55,
    Speciality: "Linguistics",
  },
  {
    SID: 56,
    Speciality: "Literature",
  },
  {
    SID: 57,
    Speciality: "Mathematics",
  },
  {
    SID: 58,
    Speciality: "Medicine",
  },
  {
    SID: 59,
    Speciality: "Internal Medicine",
  },
  {
    SID: 60,
    Speciality: "Pediatrics",
  },
  {
    SID: 61,
    Speciality: "Surgery",
  },
  {
    SID: 62,
    Speciality: "Psychiatry",
  },
  {
    SID: 63,
    Speciality: "Obstetrics",
  },
  {
    SID: 64,
    Speciality: "Gynecology",
  },
  {
    SID: 65,
    Speciality: "Music",
  },
  {
    SID: 66,
    Speciality: "Philosophy",
  },
  {
    SID: 67,
    Speciality: "Physics",
  },
  {
    SID: 68,
    Speciality: "Political Science",
  },
  {
    SID: 69,
    Speciality: "Psychology",
  },
  {
    SID: 70,
    Speciality: "Sociology",
  },
  {
    SID: 71,
    Speciality: "Theater and Performing Arts",
  },
  {
    SID: 72,
    Speciality: "Theology and Religious Studies",
  },
  {
    SID: 73,
    Speciality: "Visual Arts",
  },
];
